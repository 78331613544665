@import url('//fonts.googleapis.com/css?family=Open+Sans:400,700,400i,700i&display=swap');
@import url('//use.typekit.net/whc5ilo.css');
@import "/.publisher/sd/labmicta/vademecumsite/fonts/fontawesome/css/all.min.css";

/*
.tk-din-2014 { font-family: "din-2014",sans-serif; }
.tk-din-2014-narrow { font-family: "din-2014-narrow",sans-serif; }
*/

html
{
  color: #606060;
  font: 20px 'Open Sans', sans-serif, serif, Arial;
}

h1, h2, h3, h4
{
  line-height: 105%;
  color: #138B99;
  font-display:  swap;
  font-family: "din-2014", sans-serif, serif, Arial;
}

h1
{
  font-size: 54px;
  margin-bottom: 25px;
}
h2
{
  font-size: 32px;
  margin-bottom: 30px;
}
h3
{
  font-size: 26px;
  line-height: 130%;
  margin-bottom: 10px;
}
h3 small
{
  font-weight: 300;
  font-size: 100%;
}
h4
{
  font-size: 19px;
  line-height: 130%;
  margin-bottom: 10px;
}

p a, ul.unordered li a, ol.ordered li a
{
  color: #C2006A;
  text-underline-position: under;
}
p a:hover, ul.unordered li a:hover, ol.ordered li a:hover
{
  text-shadow: 0 0 1px #138B99;
}

p
{
  margin-bottom: 30px;
  line-height: 165%;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
, ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
  line-height: 150%;
  list-style-type: none;
}
  ul.unordered
, ol.ordered
{
  margin-bottom: 40px;
}

  article * ul.unordered
, article * ol.ordered
{
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  padding-left: 0;
}

  p + ul.unordered
, p + ol.ordered
{
  margin-top: -30px;
}

  ul.unordered li
, ol.ordered li
{
  position: relative;
  padding: 0 0 4px 20px;
  min-height: 15px;
}
  ul.unordered ul
, ol.ordered ul
, ul.unordered ol
, ol.ordered ol
{
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}
  ul.unordered li::before
, ol.ordered li::before
{
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}
  ol.ordered ul > li
, ul.unordered ul > li
{
  padding-left: 15px;
}
  ul.unordered > li::before
, ul.unordered ul > li::before
, ol.ordered ul > li::before
{
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 10px;
}

ul.unordered > li::before
{
  content: "\25cf"; /* solid circle */
  text-align: center;
  font-size: 14px;
  top:  -1px;
}

  ol.ordered
, li ol
{
  counter-reset: numlist;
}
ol.ordered li:before
{
  counter-increment: numlist;
  content: counter(numlist, decimal) ".";
}
ol.ordered ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-alpha) ".";
}
ol.ordered ol ol li:before
{
  counter-increment: numlist;
  content: counter(numlist, lower-roman) ".";
}

/* Table styling */
/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  padding-left: 15px;
  background-color: #C80D5E;
  color:  #fff;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding:  5px 15px;
}
  .wh-rtd__table th + *
, .wh-rtd__table td + *
{
  padding-left: 0;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #C80D5E;
}
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}
