.xt-lightbox-mask {
    background: #000;
    opacity: .8;
    filter: alpha(opacity=80);
    -moz-opacity: .8;
    -khtml-opacity: .8;
    z-index: 1000;
}
.xt-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
}
.xt-lightbox *
{
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.xt-lightbox .button {
    cursor: pointer;
    padding: 0;
    margin: 0;
    border: 0;
}
.xt-lightbox-wrapper {
    background: #fff;
    box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.2);
    margin: 20px;
    padding: 10px;
    position: relative;
}
.xt-lightbox-content-wrapper {
    position: relative;
}
.xt-lightbox-content {
    position: relative;
    min-width: 200px;
    min-height: 100px;
}
.xt-lightbox.loading .xt-lightbox-content {
    background: url('../../web/img/loading.gif') no-repeat 50% 50%;
}
.xt-lightbox-content img {
    display: block;
}
.xt-lightbox-content iframe {
    display: block;
    z-index: 1;
}
.xt-lightbox-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 5;
}
.xt-lightbox-prev,
.xt-lightbox-next {
    width: 49%;
    height: 100%;
    background: transparent url('../../web/img/blank.gif') repeat;
    display: block;
    top: 0;
}
.xt-lightbox-prev {
    left: 0;
    float: left;
}
.xt-lightbox-prev:hover {
    background: transparent url('../../web/img/prevlabel.gif') no-repeat 0 50%;
}
.xt-lightbox-next {
    right: 0;
    float: right;
}
.xt-lightbox-next:hover {
    background: transparent url('../../web/img/nextlabel.gif') no-repeat 100% 50%;
}
.xt-lightbox-footer {
    font-family: Verdana, Arial, Geneva, Helvetica, sans-serif;
    font-size: 10px;
    color: #666;
    line-height: 1.4em;
    overflow: hidden;
}
.xt-lightbox-footer-wrapper {
    padding-top: 10px;
}
.xt-lightbox-title {
    font-weight: bold;
}
.xt-lightbox-title, .xt-lightbox-position {
    margin-right: 76px;
}
.xt-lightbox .xt-lightbox-close {
    background: transparent url('../../web/img/closelabel.gif') no-repeat 0 0;
    margin-top: 3px;
    width: 86px;
    height: 22px;
    float: right;
}
